import React, { useState } from "react"
import Link from "gatsby-link"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const Footer = () => {
  return (
    <footer className="black">
      <div className="container">
        <div>
          <Link title={"View our terms of use"} to="/terms-of-use">
            <span className="">Terms of Use</span>
          </Link>
          <OutboundLink
            href="mailto:press@voteforyourlife.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Press Contact
          </OutboundLink>
          <OutboundLink href="https://www.viacomcbsprivacy.com/policy">
            Privacy Policy
          </OutboundLink>
          <OutboundLink href="https://www.viacomcbsprivacy.com/cookies">
            Cookies Policy
          </OutboundLink>
          <OutboundLink href="https://www.viacomcbsprivacy.com/donotsell">
            Do Not Sell My Personal Information
          </OutboundLink>{" "}
          {/* <a>
                <Link to={"http://www.google.com"}>Privacy Policy</Link>
              </a>
              <a>
                <Link to={"http://www.google.com"}>Terms of Use</Link>
              </a>
              <a>
                <Link to={"http://www.google.com"}>Contact</Link>
              </a> */}
        </div>
        <div>&copy; Viacom International Inc</div>
      </div>
    </footer>
  )
}

export default Footer
