import React from "react"
import Link from "gatsby-link"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Layout from "../components/layout"

const PrivacyPolicy = props => (
  <Layout location={props.location}>
    <section className="bg black">
      <article className="full-page">
        <div className="body type--body">
          <h2 className="type--header type--white type--subheader">
            TERMS OF USE AGREEMENT
          </h2>
          <p>
            <OutboundLink
              href="https://www.viacomcbsprivacy.com/policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </OutboundLink>
          </p>

          <p>
            Last modified August 20, 2018 <br />
            (See{" "}
            <a href="http://legal.viacom.com/Changes/PA">
              Summary of Most Significant Changes
            </a>{" "}
            to learn more.)
          </p>

          <h4 id="terms-of-use-agreement">TERMS OF USE AGREEMENT</h4>

          <p>
            PLEASE READ THESE TERMS OF USE CAREFULLY BECAUSE THEY DESCRIBE YOUR
            RIGHTS AND RESPONSIBILITIES.{" "}
          </p>

          <p>
            This is the official Terms of Use Agreement (“Agreement”) for the
            website, application or other interactive service that includes an
            authorized link to this Agreement and all other websites,
            applications and other interactive services you also use that are
            offered by the specific VII Brand (as defined below) that is
            providing this website, application or other interactive service
            (collectively, all such websites, applications and other interactive
            services, “<u>Site</u>,” “<u>we</u>,” “<u>us</u>” or “<u>our</u>“).
            The Site is offered in cooperation or connection with MTV, one of
            the brands owned, operated and/or provided by Viacom International
            Inc. (Viacom International Inc., along with its Affiliates, shall be
            referred to collectively as “<u>VII</u>”) through its Viacom Media
            Networks division, which offers television channel or programming
            services (such as television networks, websites, applications or
            other interactive services) and offers other products and services
            under various brands, such as those Viacom Media Networks brands and
            other VII brands listed{" "}
            <a href="http://viacom.com/brands/pages/default.aspx">here</a>{" "}
            (each, a “<u>VII Brand</u>”). This Site together with the MTV
            television channel or programming service, if any, shall be referred
            to collectively as the “<u>Channel</u>”. “<u>Affiliates</u>” refers
            to Viacom International Inc.’s parent company Viacom Inc. and all
            affiliates that Viacom Inc. directly or indirectly owns or controls
            (such as Paramount Pictures Corporation and the other affiliates of
            Viacom Media Networks as described in the link{" "}
            <a href="http://viacom.com/brands/pages/default.aspx">here</a>).
          </p>

          <p>This Agreement covers the following areas: </p>

          <ol>
            <li>
              <Link to="#section-1">
                Eligibility; Additional Terms; Binding Agreement.
              </Link>
            </li>
            <li>
              <Link to="#section-2">Registration.</Link>
            </li>
            <li>
              <Link to="#section-3">Modifications to this Agreement.</Link>
            </li>
            <li>
              <Link to="#section-4">Ownership of Intellectual Property.</Link>
            </li>
            <li>
              <Link to="#section-5">Advertising.</Link>
            </li>
            <li>
              <Link to="#section-6">Rules of Conduct.</Link>
            </li>
            <li>
              <Link to="#section-7">Shopping.</Link>
            </li>
            <li>
              <Link to="#section-8">
                Text Messaging Marketing and Promotions.
              </Link>
            </li>
            <li>
              <Link to="#section-9">
                Closed Captioning of Internet Protocol-Delivered Video
                Programming.
              </Link>
            </li>
            <li>
              <Link to="#section-10">Postings.</Link>
            </li>
            <li>
              <Link to="#section-11">
                Contest, Sweepstakes, Voting Rules, Auctions and Other
                Promotions.
              </Link>
            </li>
            <li>
              <Link to="#section-12">Certain Products or Services.</Link>
            </li>
            <li>
              <Link to="#section-13">Hyperlinks to Third Party Sites.</Link>
            </li>
            <li>
              <Link to="#section-14">
                Deactivation/Termination of Your Registration or Use.
              </Link>
            </li>
            <li>
              <Link to="#section-15">
                Disclaimers and Limitations of Liability.
              </Link>
            </li>
            <li>
              <Link to="#section-16">Indemnification.</Link>
            </li>
            <li>
              <Link to="#section-17">Ads and Malware.</Link>
            </li>
            <li>
              <Link to="#section-18">Privacy.</Link>
            </li>
            <li>
              <Link to="#section-19">
                Resolution of Disputes; Binding Arbitration; No Class or
                Representative Actions or Arbitrations.
              </Link>
            </li>
            <li>
              <Link to="#section-20">
                Governing Law, Arbitration Location, Jurisdiction, Venue and
                Jury Trial Waiver.
              </Link>
            </li>
            <li>
              <Link to="#section-21">Miscellaneous Terms. </Link>
            </li>
          </ol>

          <p>
            <a id="section-1"></a>
          </p>

          <h3 id="1-eligibility-additional-terms-binding-agreement">
            1. ELIGIBILITY; ADDITIONAL TERMS; BINDING AGREEMENT.
          </h3>

          <p>
            You must be at least 13 years of age or older (“
            <u>Site Minimum Age</u>”) to use this Site and this Site is designed
            and targeted to audiences residing in, and is governed by and
            operated in accordance with the laws of, the United States of
            America, its territories and possessions (“<u>U.S.</u>”), but
            certain features on this Site may be subject to heightened age
            and/or other eligibility requirements. For example, if this Site
            requires you to be at least 13 years of age, please be aware that
            certain areas on the Site may contain content for which you must be
            at least 17 years of age to access and view even if this Site’s age
            eligibility requirements are less than 17 years of age. If you are
            not yet the Site Minimum Age or the required greater age for certain
            features, do not reside in the U.S. and/or do not meet any other
            eligibility requirements, or, if for any reason, you do not agree
            with all of the terms and conditions contained in this Agreement,
            please discontinue using the Site immediately because by using or
            attempting to use the Site, you certify that you are at least the
            Site Minimum Age or other required greater age for certain features,
            meet any other eligibility and residency requirements of the Site
            and agree to all of the terms and conditions of this Agreement.{" "}
          </p>

          <p>
            These terms and conditions regarding your use of the Site constitute
            a legally binding agreement between you, on the one hand, and the
            Channel and VII, on the other hand.{" "}
          </p>

          <p>
            In this Agreement, the term “Site” includes all websites and web
            pages within or associated with the Site (such as third level domain
            names and other subdomains) as well as any equivalent, mirror,
            replacement, substitute or backup websites and web pages that are
            associated with the Site. By using this Site, you understand,
            acknowledge and agree that you will abide by the terms of this
            Agreement and any additional terms that govern certain products
            and/or services which will be presented in conjunction with those
            products and/or services (“
            <u>Additional Terms</u>“), including, but not limited to, any terms
            that may be provided on the Site relating to the submission of
            content, media and materials you submit for posting on the Site (“
            <u>User Content Submission Agreement</u>”) and the rules of
            participation (“<u>Rules</u>“) which govern certain activities and
            services such as voting (for example, see the Voting Rules as
            described below) and contests and sweepstakes. The Site’s Additional
            Terms and the{" "}
            <Link to="/privacy-policy" className="link--inline">
              Privacy Policy
            </Link>{" "}
            are hereby incorporated in this Agreement by reference. If there is
            a conflict between this Agreement and Additional Terms for the
            activity which you choose to participate in, the Additional Terms
            shall govern. This Agreement will remain in full force and effect as
            long as you are a user of the Site and, in the event of termination
            of any membership, service or feature, you will still be bound by
            your obligations under this Agreement (including, but not limited
            to, the{" "}
            <Link to="/privacy-policy" className="link--inline">
              Privacy Policy
            </Link>{" "}
            and any Additional Terms), such as any indemnifications, warranties
            and limitations of liability.{" "}
          </p>

          <p>
            The words “<u>use</u>” or “<u>using</u>” in this Agreement means any
            time an individual (a “<u>user</u>“), directly or indirectly, with
            or without the aid of a machine or device, does or attempts to
            access, interact with use, display, view, print or copy from the
            Site, transmit, receive or exchange data or communicate with the
            Site, or in any way utilizes, benefits, takes advantage of or
            interacts with any function, service or feature of the Site, for any
            purpose whatsoever. This Agreement does not cover your rights or
            responsibilities with respect to third party content or sites or any
            links that may direct your browser or your connection to third party
            sites or pages. This is the entire and exclusive Agreement between
            you and us regarding use of the Site and it cannot be modified,
            except as specifically described below in <u>Section 3</u>. This
            Agreement applies regardless of whether you are accessing the Site
            via a personal computer, a mobile device or any other technology or
            devices now known or hereafter developed or discovered (each, a “
            <u>Device</u>”).
          </p>

          <p>
            <a id="section-2"></a>
          </p>

          <h3 id="2-registration">2. REGISTRATION</h3>

          <p>
            If we offer user registration on the Site, to register as a member
            of the Site, there may be a few options for you to register (which
            are further described in our{" "}
            <Link to="/privacy-policy" className="link--inline">
              Privacy Policy
            </Link>
            ): Site registration, Third Party Social Media Network Sign-On (as
            described in our Privacy Policy), and, to the extent you have
            previously registered on another participating VII website,
            application or other interactive service using one of those methods,
            Single VII Sign-On (as defined and described in our Privacy Policy).
            In each case, you are required to either use a pre-existing user
            name and password (together, your “<u>User ID</u>”) or select a new
            unique User ID in order to access and use certain features or
            functions of the Site. We may also, from time to time, provide users
            with additional codes or passwords necessary to access and use
            certain features or functions of the Site. Please read our{" "}
            <Link to="/privacy-policy" className="link--inline">
              Privacy Policy
            </Link>
            , which provides additional information on options we may provide to
            register on the Site and the information we collect, use, disclose,
            manage and store. Your user name and password are personal to you
            and you may not allow any others to use your user name or password
            under any circumstances. We are not liable for any harm caused or
            related to the theft or misappropriation of your user name or
            password, disclosure of your user name or password, or your
            authorization of anyone else to use your user name or password. You
            agree to immediately notify us if you become aware of or believe
            there is or may have been any unauthorized use of (or activity
            using) your user name or password or any other need to deactivate
            your user name or password due to security concerns.{" "}
          </p>

          <p>
            <a id="section-3"></a>
          </p>

          <h3 id="3-modifications-to-this-agreement">
            3. MODIFICATIONS TO THIS AGREEMENT
          </h3>

          <p>
            We reserve the right to revise the terms of this Agreement, at any
            time and from time to time, for any reason in our sole discretion by
            posting an updated Terms of Use Agreement without advance notice to
            you. We shall post or display notices of material changes on the
            Site’s homepage and/or otherwise on the Site and/or e-mail you or
            notify you upon login about these changes; the form of such notice
            is at our discretion. Once we post them on the Site, these changes
            become effective immediately and, if you use the Site after they
            become effective, it will signify your agreement to be bound by the
            changes. You should check back frequently and regularly review the
            terms and conditions of this Agreement, including, but not limited
            to, any Additional Terms and the Privacy Policy, so you are aware of
            the most current rights and obligations that apply to you and the
            terms and conditions of your agreement with us.{" "}
          </p>

          <p>
            <a id="section-4"></a>
          </p>

          <h3 id="4-ownership-of-intellectual-property">
            4. OWNERSHIP OF INTELLECTUAL PROPERTY
          </h3>

          <p>
            The contents of this Site, including all Site software, design,
            text, images, photographs, illustrations, audio and video material,
            artwork, graphic material, databases, proprietary information and
            all copyrightable or otherwise legally protectible elements of the
            Site, including, without limitation, the selection, sequence and
            ‘look and feel’ and arrangement of items, and all trademarks,
            service marks and trade names (individually and/or collectively, “
            <u>Material</u>“), are the property of VII, and any of its
            successors and assigns, and any of its respective licensors (for
            example, certain software on this Site may contain software owned by
            third parties and Postings may be owned by you or third parties),
            Advertisers (as defined below), suppliers, and operational service
            providers and are legally protected under applicable U.S. and
            foreign laws, rules and regulations and international treaties.
            Unless the context clearly requires otherwise or we explicitly say
            so in writing, the term “Site” includes “Material” as well. The Site
            is to be used solely for your noncommercial, non-exclusive,
            non-assignable, non-transferable and limited personal use and for no
            other purposes. You must not alter, delete or conceal any copyright
            or other notices contained on the Site, including notices on any
            Material you are permitted to download, transmit, display, print or
            reproduce from the Site. You shall not, nor will you allow any third
            party (whether or not for your benefit) to reproduce, modify, create
            derivative works from, display, perform, publish, distribute,
            disseminate, broadcast or circulate to any third party (including,
            without limitation, on or via a third party website), or otherwise
            use, any Material without the express prior written consent of VII
            or its owner if VII is not the owner. Any unauthorized or prohibited
            use of any Material may subject you to civil liability, criminal
            prosecution, or both, under applicable federal, state and local
            laws. We require users to respect our copyrights, trademarks, and
            other intellectual property rights. We likewise respect the
            intellectual property of others. On notice, we will act
            expeditiously to remove content on the Site that infringes the
            copyright rights of others and will disable the access to the Site
            and its services of anyone who uses them to repeatedly to infringe
            the intellectual property rights of others.{" "}
          </p>

          <p>
            We take protection of copyrights, both our own and others, very
            seriously. We therefore employ multiple measures to prevent
            copyright infringement over this Site and to promptly end any
            infringement that might occur. If you believe that the Site contains
            elements that infringe your copyrights in your work, please follow
            the procedures set forth in our{" "}
            <a href="/legal/copyright-compliance-policy">
              Copyright Compliance Policy
            </a>
            .{" "}
          </p>

          <p>
            <a id="section-5"></a>
          </p>

          <h3 id="5-advertising">5. ADVERTISING</h3>

          <p>
            From time to time, you may communicate with, receive communications
            from, be re-directed to, interact with, or participate in or use the
            services or obtain goods and services of or from, third parties
            (collectively, the “<u>Advertisers</u>“), such as our advertisers,
            sponsors, or promotional partners, as a result of your use of the
            Site. All such communication, interaction and participation is
            strictly and solely between you and such Advertisers and we shall
            not be responsible or liable to you in any way in connection with
            these activities or transactions (including, without limitation, any
            representations, warranties, covenants, contracts or other terms or
            conditions that may exist between you and the Advertiser or any
            goods or services you may purchase or obtain from any Advertiser).{" "}
          </p>

          <p>
            <a id="section-6"></a>
          </p>

          <h3 id="6-rules-of-conduct">6. RULES OF CONDUCT</h3>

          <p>
            Your use of the Site is subject to all applicable laws, rules and
            regulations and, in some cases, international treaties. You are
            solely responsible for all activities, acts and omissions that occur
            in, from, through or under your user name or password. You are also
            prohibited from using the Site (or attempting to use the Site, or
            allowing, enabling or condoning others to use the Site) in a manner
            that:{" "}
          </p>

          <ul>
            <li>
              is or is likely to be libelous, defamatory, indecent, vulgar or
              obscene, pornographic, sexually explicit or sexually suggestive,
              racially, culturally, or ethnically offensive, harmful, harassing,
              intimidating, threatening, hateful, objectionable, discriminatory,
              or abusive, or which may or may appear to impersonate anyone else;{" "}
            </li>
            <li>
              adversely affects or negatively reflects on us, the Channel, our
              goodwill, name or reputation or causes duress, distress or
              discomfort to us or anyone else, or is likely to do so;
            </li>
            <li>
              discourages or is likely to discourage any person, firm or
              enterprise from using all or any portion, features or functions of
              the Site, or from advertising, linking or becoming a supplier to
              us in connection with the Site;{" "}
            </li>
            <li>
              sends or results in the transmission of junk e-mail, chain
              letters, duplicative or unsolicited messages, or so-called
              “spamming” and “phishing”;{" "}
            </li>
            <li>
              uses the Site for commercial or business purposes, including,
              without limitation, advertising, marketing or offering goods or
              services, whether or not for financial or any other form of
              compensation or through linking with any other website or web
              pages;{" "}
            </li>
            <li>
              transmits, distributes or uploads programs or material that
              contain malicious code, such as viruses, timebombs, cancelbots,
              worms, trojan horses, spyware, or other potentially harmful
              programs or other material or information;{" "}
            </li>
            <li>
              forges any TCP/IP packet header or part of the header information
              in any email, newsgroup posting or other posting for any reason;{" "}
            </li>
            <li>
              violates any laws, rules or regulations (including, without
              limitation, laws regarding the transmission of technical data or
              software exported from the United States), judicial or
              governmental order or any treaties, or violates or infringes upon
              any copyright or other intellectual property rights, rights of
              publicity or privacy or any other rights of ours or of any other
              person, firm or enterprise;{" "}
            </li>
            <li>
              gains or enables unauthorized access to the Site or any of its
              features or functions, other users’ accounts, names, passwords,
              personally identifiable information or other computers, websites
              or pages, connected or linked to the Site;{" "}
            </li>
            <li>
              modifies, disrupts, impairs, alters or interferes with the use,
              features, functions, operation or maintenance of the Site or the
              use and enjoyment of the Site by any other person, firm or
              enterprise;{" "}
            </li>
            <li>
              collects, obtains, compiles, gathers, transmits, reproduces,
              deletes, revises, views or displays any material or information,
              whether personally identifiable or not, posted by or concerning
              any other person, firm or enterprise, in connection with their or
              your use of the Site, unless you have obtained the express, prior
              permission of such other person, firm or enterprise to do so;{" "}
            </li>
            <li>
              except with our prior permission, uses manual or automated
              software, devices, scripts, robots or other means or processes to
              access, “scrape,” “crawl” or “spider” any web pages or other
              services contained in the Site; or{" "}
            </li>
            <li>
              otherwise violates or is inconsistent with this Agreement,
              including, but not limited to, any Additional Terms and the
              Privacy Policy.{" "}
            </li>
          </ul>

          <p>
            <a id="section-7"></a>
          </p>

          <h3 id="7-shopping">7. SHOPPING</h3>

          <p>
            If we offer certain e-Commerce to enable you to purchase goods and
            services via our shop (the “<u>E-Commerce Service</u>” or “
            <u>Shop</u>
            ”), additional terms (such as those available on or through the Shop
            page) apply to your use and access of the E-Commerce Service which
            are Additional Terms and incorporated herein by reference.{" "}
          </p>

          <p>
            The Shop on certain VII websites, applications or other interactive
            services utilizes third party operational service providers. All
            purchases made through these third party operational service
            providers are subject to their respective terms and conditions of
            use. Additional information regarding our Shop’s third party
            operational service providers can be found on our{" "}
            <strong>Shop Account</strong> page (see below). We and VII are not
            responsible and have no liability whatsoever for goods or services
            you obtain through our third party operational service providers or
            other web sites or web pages. We encourage you to make whatever
            investigation you feel necessary or appropriate before proceeding
            with any online transaction with any of these third parties. We and
            VII do not make any representations or warranties as to the security
            of any information (including, without limitation, credit card and
            other personal information) you might be requested to give any such
            third party, and you irrevocably waive any claim against us with
            respect to such sites. We and VII are not responsible for assisting
            you in correcting any problem you may experience with goods and
            services purchased through a third party operational service
            provider, even if the goods or services were shown on one of the
            Site’s web pages. We and VII cannot ensure that you will be
            satisfied with any products or services that you purchase from any
            third party operational service provider as those are owned and
            operated by independent entities. Customer service issues related to
            goods or services should be directed to the relevant third party
            operational service provider. Please visit our{" "}
            <a href="http://shop.mtv.com/myAccount.htm">Shop Account</a> page
            for more information. <br />
            We and VII do not guarantee that product descriptions or other
            content will be accurate, complete, reliable, current, or
            error-free. Descriptions and images of, and references to, products
            on the Site do not imply our or VII’s endorsement of such products.
            Furthermore, customer reviews seen in our Shop are provided by the
            third party operational service provider’s users and are subject to
            such third party operational service provider’s own community
            guidelines. We, VII and our third party operational service
            providers reserve the right, with or without prior notice, to change
            product descriptions, images, and references; to limit the available
            quantity of any product; to honor, or impose conditions on the
            honoring of, any coupon, coupon code, promotional code or other
            similar promotions; to bar any user from conducting any or all
            transaction(s); and/or to refuse to provide any user with any
            product. Price and availability of any product are subject to change
            without notice.
          </p>

          <p>
            <a id="section-8"></a>
          </p>

          <h3 id="8-text-messaging-marketing-and-promotions">
            8. TEXT MESSAGING MARKETING AND PROMOTIONS
          </h3>

          <p>
            The Channel through VII may provide you with the opportunity to
            register for special Promotions, services, news, programming and
            information (collectively, “<u>Text Service(s)</u>”) delivered via
            text messaging on wireless Devices such as mobile phones and, if we
            do so, we will require your prior express consent for any Text
            Services in accordance with the nature of the Text Service and
            applicable laws, rules and regulations. The Information requested or
            transmitted as part of the registration process includes your
            wireless telephone number and may include other information, such as
            your preferences regarding goods or services, choices of music or
            artists, or other similar survey information and/or an email
            address. Depending on the Text Service and the Information
            collected, you may also be required to confirm your agreement to
            this Agreement (including, but not limited to, the{" "}
            <Link to="/privacy-policy" className="link--inline">
              Privacy Policy
            </Link>{" "}
            and Additional Terms).{" "}
          </p>

          <p>
            If you register for the Channel’s Text Services, you acknowledge,
            understand and agree that you may be charged by your wireless
            carrier for the ability to send or receive all such messages. The
            standard messaging rates of your wireless carrier shall apply to all
            Text Services, unless noted otherwise.{" "}
            <strong>
              Under no circumstances shall the Channel or VII be responsible for
              any wireless service charges incurred by you or by a person that
              has access to your wireless device, telephone number, or email
              address.
            </strong>{" "}
            If you change or deactivate your wireless telephone number, you
            agree to notify us when your wireless telephone number is no longer
            associated with you and identify such wireless phone number to
            ensure that future messages directed to you are not sent to the
            person to whom your old number has been or will be assigned.
          </p>

          <p>
            You understand, acknowledge and agree that this Channel may, at its
            sole discretion and without liability to you or any user, terminate
            its offer of any specific Text Service or all Text Services at any
            time without advance notice. This Channel may provide notice of
            terminations or changes in services on this Site.{" "}
          </p>

          <p>
            <a id="section-9"></a>
          </p>

          <h3 id="9-closed-captioning-of-internet-protocol-delivered-video-programming">
            9. CLOSED CAPTIONING OF INTERNET PROTOCOL-DELIVERED VIDEO
            PROGRAMMING
          </h3>

          <p>
            In accordance with the Twenty-First Century Communications and Video
            Accessibility Act of 2010, all video programming distributors that
            make video programming available directly to end users through a
            distribution method that uses the internet protocol are required to
            provide contact information to end users for the receipt and
            handling of written closed captioning complaints.{" "}
          </p>

          <p>
            If you have a complaint regarding the Site’s compliance with the
            closed captioning requirements of the Twenty-First Century
            Communications and Video Accessibility Act of 2010, please click{" "}
            <a href="http://media.viacom.com/accessibility/">here</a>.
          </p>

          <p>
            <a id="section-10"></a>
          </p>

          <h3 id="10-postings">10. POSTINGS</h3>

          <p>
            Your comments, suggestions and information are important to us.
            Portions of this Site may provide you and other users an opportunity
            to participate in forum services, blogs, web communities and other
            message and communication facilities (“<u>Communities</u>”) and may
            provide you with the opportunity, through such Communities or
            otherwise, to submit, post, display, transmit and/or exchange
            information, ideas, opinions, messages, content, media, materials or
            other information (collectively, “<u>Posts</u>” or “<u>Postings</u>
            ”). If this Site provides a User Content Submission Agreement, such
            terms govern any User Content (as defined therein) which are also
            Postings under this Agreement and such User Content Submission
            Agreement are Additional Terms under this Agreement.{" "}
          </p>

          <h4 id="101-responsibility-for-postings-our-right-to-review-monitor-edit-andor-screen-and-take-other-actions">
            10.1 Responsibility for Postings; Our Right to Review, Monitor, Edit
            and/or Screen and Take Other Actions.
          </h4>

          <p>
            You understand, acknowledge and agree that Postings are the sole
            responsibility of the person from which such Postings originated.
            This means that you are solely and entirely responsible for the
            consequences of all Postings that you (including, but not limited
            to, Postings made under your name, user name and/or email address)
            upload, post, email, transmit or otherwise make available via the
            Site. Except for the licenses, authorizations, consents and rights
            granted hereunder (including, but not limited to, as described in
            any User Content Submission Agreement that may be posted on this
            Site), as comprehensive and broad as they may seem or actually be,
            VII and the Channel acquires no title or ownership rights in or to
            any User Content you submit and nothing in this Agreement conveys to
            us any ownership rights in your User Content.
          </p>

          <p>
            Postings do not reflect the views of the Channel or VII and the
            Channel and VII do not represent or guarantee the truthfulness,
            accuracy or reliability of any Posting or endorse or support any
            opinions expressed in any Postings. Neither the Channel nor VII
            control any Postings submitted, although we may (in our sole
            discretion) use automated and/or manual means of reviewing Postings
            in order to prevent unauthorized content from appearing and being
            displayed on our Site. The Channel and VII reserve the right to
            review, monitor, edit and/or screen any Postings and to delete,
            remove, move, re-format, edit or reject, without notice to you, any
            Postings that we deem, in our sole discretion, to be in violation of
            this Agreement or to be unacceptable to the Channel or VII, or for
            any reason or for no reason whatsoever; <u>provided</u>, however,
            that the Channel and VII shall have no obligation or liability for
            failure to do so or for doing so in any particular manner. If we
            change your Postings, you will not be responsible for any such
            changes we make.{" "}
          </p>

          <p>
            If we determine, in our sole discretion and judgment, that any
            Posting does or may violate any of the terms of this Agreement, in
            addition to our rights as described in this Agreement, we reserve
            the right, at any time and without limiting any and all other rights
            we may have under this Agreement, at law or in equity, to: (i)
            refuse to allow you to Post; (ii) remove and delete Postings; (iii)
            revoke your right to use the Site; and/or (iv) use any
            technological, legal, operational or other means available to us to
            enforce the provisions of this Agreement, including, without
            limitation, blocking specific IP addresses or deactivating your
            registration on the Site.
          </p>

          <p>
            The amount of storage space on the Site per user is limited. Some
            Postings may not be processed due to space constraints or outbound
            message limitations. You understand, acknowledge and agree that we
            assume no responsibility for deletion of Postings or any failure to
            store, receive or deliver Postings in a timely manner or any other
            matter relating to Postings. Postings are subject to all of the
            terms of this Agreement, including, but not limited to, the Rules of
            Conduct.{" "}
          </p>

          <h4 id="102-grant-of-rights-representations-warranties-and-covenants">
            10.2 Grant of Rights; Representations, Warranties and Covenants.
          </h4>

          <p>
            If a Posting originates from you or your account, you hereby agree
            that (and each time you submit (or attempt to submit) a Posting, you
            reaffirm such agreement that): (i) you specifically authorize the
            Channel and VII to use such Posting in whole or in part, throughout
            the universe, in perpetuity in or on any and all media, now known or
            hereafter devised, and alone or together with or as part of other
            information, content and/or material of any kind or nature; (ii) you
            represent, warrant and covenant that (A) the Posting is original to
            you and/or fully cleared for use as contemplated herein, (B) the
            Posting does and will not, in any way, violate or breach any of the
            terms of this Agreement, (C) the Posting does not contain libelous,
            tortious, obscene or otherwise unlawful material or information, (D)
            the Posting, and the Channel’s and VII’s exercise of the rights you
            grant to the Channel and VII to the Postings, will not infringe
            upon, misappropriate or otherwise violate any copyright or other
            intellectual property, privacy, publicity or other rights of any
            party, or otherwise violate any other applicable law, rule or
            regulation, (E) the Posting shall not be injurious to the health of
            any user, (F) neither the Channel nor VII shall be required to pay
            or incur any sums to any person or entity as a result of our
            permitted use or exploitation of the Posting, and (G) all of the
            information provided by you associated with your Posting is complete
            and accurate; and (iii) if your Posting incorporates the name, logo,
            brand, service or trademark, voice, likeness or image of any person,
            firm or enterprise, you additionally represent, warrant and covenant
            to the Channel and VII that (A) you have the right to grant the
            Channel and VII the right to use all such Postings as described
            above, (B) the Posting was produced in compliance with all
            applicable laws and regulations and (C) for any Posting that
            contains original videos, you will comply with any applicable
            identification verification and record-keeping requirements, and our
            policies regarding governmental certification procedures relating
            thereto, and you will secure and maintain the requisite personal
            information and identification documentation for all individuals who
            appear in any such original videos, as may be required by law and/or
            otherwise requested or required by us in connection with our
            corporate compliance policies and practices, which includes (y) the
            individual’s full legal name, current address, date of birth and (z)
            a legible photocopy of a valid government-issued identification
            document (e.g., a U.S. passport, state driver’s license or valid
            photo ID card) to verify the individual’s identity. With the
            submission of each such Posting, the Channel and VII reserve the
            right to request that you, and upon such request you must, deliver a
            full and complete set of such identification verification records to
            us, as well as a legible photocopy of your valid driver’s license,
            passport or other acceptable government-issued photo identification
            for our verification and record-keeping purposes.{" "}
          </p>

          <p>
            Upon the request of the Channel or VII, you will promptly furnish
            any documentation, substantiation and releases necessary and
            reasonably required to verify and substantiate your compliance with
            any or all of the terms and conditions of this Agreement, including,
            without limitation, the foregoing representations, warranties and
            covenants and, if applicable, you will promptly comply with any
            specific requests or directions we give you in connection with
            Postings you submit that may be subject to these requirements.{" "}
          </p>

          <p>
            Notwithstanding anything to the contrary, you also understand and
            agree that the Channel and VII shall not be obligated or responsible
            for providing any guild or residual payments in connection with the
            submission and exploitation of any Posting.{" "}
          </p>

          <h4 id="103-claims-regarding-content">
            10.3 Claims Regarding Content.
          </h4>

          <p>
            If you believe that any content on the Site (including, without
            limitation, Postings) violates any of the terms of this Agreement
            (except for any notices covered by the Copyright Compliance Policy),
            please email us at{" "}
            <a href="mailto:support@lookdifferent.org">
              support@lookdifferent.org
            </a>{" "}
            (please refer to our{" "}
            <a href="/legal/copyright-compliance-policy">
              Copyright Compliance Policy
            </a>{" "}
            for any notices covered by the Copyright Compliance Policy). We
            cannot guarantee that we will respond to your message and we reserve
            the right to take or refrain from taking any or all steps available
            to us once we receive any such message.{" "}
          </p>

          <h4 id="104-certain-rights-of-removal-for-postings">
            10.4 Certain Rights of Removal for Postings.
          </h4>

          <p>
            Registered users of this Site who are California residents and are
            under 18 years of age may request and obtain removal of Postings on
            this Site that they themselves post by emailing us at{" "}
            <a href="mailto:support@lookdifferent.org?subject=California+Removal+Request">
              support@lookdifferent.org
            </a>
            .{" "}
            <strong>
              All requests must be labeled “California Removal Request” on the
              email subject line.
            </strong>{" "}
            All requests must provide a description of the content or
            information in your Posting that you want removed and information
            reasonably sufficient to permit us to locate the material and, so
            that we can process your request and contact you if we have
            questions, include your registered username, name, street address,
            city, state, zip code and email address (your street address is
            optional if you wish to receive a response to your request via
            email), include the name and URL (if applicable) of the website,
            application or other interactive service and indicate your
            preference on how our response to your request should be sent (email
            or postal mail). We shall not accept requests via postal mail,
            telephone or facsimile. We are not responsible for notices that are
            not labeled or sent properly, or may not be able to respond if you
            do not provide complete information.{" "}
          </p>

          <p>
            Please also note that any requests for removal do not ensure
            complete or comprehensive removal of the content or information from
            this Site. For example, content that you have posted may be
            republished or reposted by another user or third party. See also{" "}
            <u>Section 8</u> of our Privacy Policy regarding how you may delete
            certain Information.
          </p>

          <p>
            <a id="section-11"></a>
          </p>

          <h3 id="11-contests-sweepstakes-auctions-and-promotions">
            11. CONTESTS, SWEEPSTAKES, AUCTIONS AND PROMOTIONS
          </h3>

          <p>
            From time to time, this Channel, VII and/or their Advertisers,
            operational service providers and suppliers may conduct promotions
            on or through the Site, including, but not limited to, auctions,
            contests and sweepstakes (“<u>Promotions</u>“). Each Promotion may
            have Additional Terms, such as the rules governing any voting
            relating to a Promotion (the “<u>Voting Rules</u>”) (Voting Rules
            are available by{" "}
            <a href="http://legal.viacom.com/VotingRules">clicking here</a>) and
            other rules relating to the Promotion, which will be posted or
            otherwise made available to you and, for purposes of each Promotion,
            are Additional Terms and will be deemed incorporated into and form a
            part of this Agreement. In the event of a conflict between the
            Voting Rules available by link in this Section and the Voting Rules
            specific to the Promotion, the Voting Rules specific to the
            Promotion shall govern with respect to the specific Promotion.
          </p>

          <p>
            <a id="section-12"></a>
          </p>

          <h3 id="12-certain-products-and-services">
            12. CERTAIN PRODUCTS AND SERVICES
          </h3>

          <h4 id="121-rss-feeds-and-podcasts">12.1 RSS Feeds and Podcasts.</h4>

          <p>
            The Site may provide RSS Feeds (“<u>RSS Feeds</u>“) consisting of
            selected text, audio, video, and photographic content (“
            <u>Content</u>
            “) from the Site that is provided over the Internet using an XML
            feed. Certain RSS Feeds may be podcasts (“<u>Podcasts</u>“) which
            may include as part of the Content an associated audio, video and/or
            photographic file where the audio and/or video file may be
            downloaded and played from a user’s Device or (if permitted)
            transferred to another Device. Certain software and hardware is
            required for users to download and view and/or play Content through
            RSS Feeds.{" "}
          </p>

          <p>
            Content is Material and subject to all of the terms of this
            Agreement, even though you may download, copy and/or transfer to a
            Device, or through a Device to another Device, the RSS Feeds and
            associated Content.{" "}
          </p>

          <p>
            By your access to and use of RSS Feeds, you understand, acknowledge
            and agree that this Channel and VII do not warrant that its RSS
            Feeds will operate on all Devices. Please see the “
            <Link to="#section-15">
              Disclaimer and Limitations of Liability
            </Link>
            ” section below for further details.
          </p>

          <h4 id="122-mobile-applications">12.2 Mobile Applications.</h4>

          <p>
            If this Channel offers products and services through applications
            available on your wireless or other mobile Device (such as a mobile
            phone) (the “<u>Mobile Application Services</u>”), such as
            applications you download or text messaging services, these Mobile
            Application Services are governed by the Additional Terms presented
            in connection with the applicable Mobile Application Service. These
            Mobile Application Services may be provided at no charge to you or
            may be available for a fee, as provided in the applicable Additional
            Terms, including, but not limited to, the terms presented to you in
            connection with your download of such Mobile Application Service. In
            addition, your wireless carrier’s standard messaging rates and other
            messaging, data and other rates and charges will apply to certain
            Mobile Application Services. You should check with your carrier to
            find out what plans your carrier offers and how much the plans cost.
            In addition, the use or availability of certain Mobile Application
            Services may be prohibited or restricted by your wireless carrier,
            and not all Mobile Application Services may work with all wireless
            carriers or Devices. Therefore, you should check with your wireless
            carrier to find out if the Mobile Application Services are available
            for your wireless Device, and what restrictions, if any, may be
            applicable to your use of such Mobile Application Services. If you
            change or deactivate your wireless telephone number, you agree to
            promptly update your Mobile Application Services account
            information, or otherwise notify us that the wireless telephone
            number is no longer associated with you and identify such wireless
            phone number, to ensure that future messages directed to you are not
            sent to the person to whom your old number has been or will be
            assigned.{" "}
          </p>

          <p>
            <strong>
              Under no circumstances will this Channel or VII be responsible for
              any wireless service charges incurred by you or by a person that
              has access to your wireless device, telephone number, or email
              address using any Mobile Application Services.
            </strong>
          </p>

          <h4 id="123-viral-features">12.3 Viral Features.</h4>

          <p>
            There may be portions of our Site, content, functionality or
            features (e.g, digital streaming media player(s)) (“
            <u>Viral Features</u>“) that we make available to users for your
            personal use. While we can change how, to whom and to what extent we
            make these Viral Features available at any time without any notice
            and in our sole discretion, so long as they are available to you,
            whenever you visit our Site or take advantage of any of these Viral
            Features, you agree not to download any content made available as
            part of the Viral Features and acknowledge that such content is
            available only for streaming viewing and, further, that you are
            bound by the applicable provisions of this Agreement and our{" "}
            <Link to="/privacy-policy" className="link--inline">
              Privacy Policy
            </Link>
            .{" "}
          </p>

          <p>
            <a id="section-13"></a>
          </p>

          <h3 id="13-hyperlinks-to-third-party-sites">
            13. HYPERLINKS TO THIRD PARTY SITES
          </h3>

          <p>
            The appearance, availability, or your use of URLs or hyperlinks
            referenced or included anywhere on the Site or any other form of
            link or re-direction of your connection to, with or through the
            Site, does not constitute an endorsement by, nor does it incur any
            obligation, responsibility or liability on the part of, the Channel
            or VII, any of their successors and assigns, and any of their
            respective officers, directors, employees, agents, representatives,
            licensors, Advertisers, suppliers, and operational service
            providers. We do not verify, endorse or have any responsibility for
            any such third party sites, their business practices (including
            their privacy policies), or any goods or services associated with or
            obtained in connection with any such site, even if the Channel’s or
            VII’s logo(s) or sponsorship identification is on the third party
            site as part of a co-branding or promotional arrangement. If any
            third party site obtains or collects Personal Information or other
            information from you, in no event shall we assume or have any
            responsibility or liability. Please read our{" "}
            <Link to="/privacy-policy" className="link--inline">
              Privacy Policy
            </Link>
            , which describes how this Channel collects and uses your Personal
            Information and other information and certain of our relationships.{" "}
          </p>

          <p>
            <a id="section-14"></a>
          </p>

          <h3 id="14-deactivationtermination-of-your-registration-or-use">
            14. DEACTIVATION/TERMINATION OF YOUR REGISTRATION OR USE
          </h3>

          <p>
            If this Site offers user registration and you are registered to use
            the Site, you may deactivate your account on the Site, at any time
            and for any reason, by logging in to your account, and then
            following the instructions on the Site to deactivate your account.
            If you are unable to deactivate your account on this Site using this
            method or otherwise have questions about how to deactivate your
            account, please contact us at{" "}
            <a href="mailto:support@lookdifferent.org">
              support@lookdifferent.org
            </a>
            .{" "}
          </p>

          <p>
            We may terminate your use of and registration on the Site, at any
            time and for any reason, with or without cause, without prior notice
            to you and without any liability or further obligation of any kind
            whatsoever to you or any other party. See our{" "}
            <Link to="/privacy-policy" className="link--inline">
              Privacy Policy
            </Link>{" "}
            for more information about deactivation or termination of accounts
            by parents or legal guardians if you have not yet reached the age of
            majority where you live.
          </p>

          <p>
            <a id="section-15"></a>
          </p>

          <h3 id="15-disclaimer-and-limitations-of-liability">
            15. DISCLAIMER AND LIMITATIONS OF LIABILITY
          </h3>

          <p>
            THIS SITE, AND ALL MATERIALS, GOODS AND SERVICES AND POSTINGS ARE
            MADE AVAILABLE ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY
            REPRESENTATION OR WARRANTY OF ANY KIND, EXPRESS OR IMPLIED
            (INCLUDING, WITHOUT LIMITATION, WARRANTIES OF MERCHANTABILITY AND
            FITNESS FOR A PARTICULAR PURPOSE) OR ANY GUARANTY OR ASSURANCE THE
            SITE WILL BE AVAILABLE FOR USE, OR THAT ALL FEATURES, FUNCTIONS OR
            OPERATIONS WILL BE AVAILABLE OR PERFORM AS DESCRIBED. WITHOUT
            LIMITING THE FOREGOING, WE ARE NOT RESPONSIBLE OR LIABLE FOR ANY
            MALICIOUS CODE, DELAYS, INACCURACIES, ERRORS, OR OMISSIONS RELATING
            TO YOUR USE OF THE SITE. You understand, acknowledge and agree that
            you are assuming the entire risk as to the quality, accuracy,
            performance, timeliness, adequacy, completeness, correctness,
            authenticity, security and validity of any and all features and
            functions of the Site, including, without limitation, Postings and
            Materials associated with your use of the Site.{" "}
          </p>

          <p>
            YOU UNDERSTAND AND AGREE THAT, TO THE FULLEST EXTENT PERMISSIBLE BY
            LAW, THIS SITE, THIS CHANNEL, VII, AND THEIR RESPECTIVE SUCCESSORS
            AND ASSIGNS, AND ANY OF THEIR RESPECTIVE OFFICERS, DIRECTORS,
            EMPLOYEES, AGENTS, REPRESENTATIVES, LICENSORS, OPERATIONAL SERVICE
            PROVIDERS, ADVERTISERS, OR SUPPLIERS, SHALL NOT BE LIABLE FOR ANY
            LOSS OR DAMAGE OF ANY KIND, DIRECT OR INDIRECT, RELATING TO THE SITE
            OR THIS AGREEMENT, INCLUDING, BUT NOT LIMITED TO, COMPENSATORY,
            CONSEQUENTIAL, INCIDENTAL, DIRECT, INDIRECT, SPECIAL OR PUNITIVE
            DAMAGES, HOWEVER ARISING OR CAUSED, WHETHER FORESEEABLE OR NOT,
            REGARDLESS OF THE FORM OF ACTION OR THE BASIS OR CHARACTERIZATION OF
            THE CLAIM AND EVEN IF ADVISED OF THE POSSIBILITY OF DAMAGES. WITHOUT
            LIMITING THE FOREGOING, THE SITE IS MAINTAINED ON SERVERS IN THE
            UNITED STATES AND THE CHANNEL AND VII DISCLAIM ALL LIABILITY FOR ANY
            USE NOT SPECIFICALLY AUTHORIZED OR THAT IS IN VIOLATION OF THIS
            AGREEMENT OR THE LAWS OR REGULATIONS THAT MAY APPLY TO YOU IN ANY
            JURISDICTION OR COUNTRY.
          </p>

          <p>
            Notwithstanding any claim that a sole or exclusive remedy which is
            provided in this Agreement may or does fail of its essential
            purpose, you specifically acknowledge and agree that your sole and
            exclusive remedy for any loss or damage shall be to have VII, upon
            written notice from you to us, attempt to repair, correct or replace
            any deficient goods or services under this Agreement and, if repair,
            correction or replacement is not reasonably commercially practicable
            for VII, to refund any monies actually paid by you to VII for the
            goods or services involved and to terminate and discontinue your use
            of the Site. You further understand and acknowledge the capacity of
            the Site, in the aggregate and for each user, is limited.
            Consequently some messages and transmissions may not be processed in
            a timely fashion or at all, and some features or functions may be
            restricted or delayed or become completely inoperable. As a result,
            you acknowledge and agree that VII assumes no liability,
            responsibility or obligation to transmit, process, store, receive or
            deliver transactions or Postings or for any failure or delay
            associated with any Postings and you are hereby expressly advised
            not to rely upon the timeliness or performance of the Site for any
            transactions or Postings. Some jurisdictions do not allow for the
            exclusion of certain warranties or certain limitations on damages
            and remedies; accordingly some of the exclusions and limitations
            described in this Agreement may not apply to you.{" "}
          </p>

          <p>
            <a id="section-16"></a>
          </p>

          <h3 id="16-indemnification">16. INDEMNIFICATION</h3>

          <p>
            You agree to indemnify, defend and hold the Site, the Channel and
            VII, and any of their successors and assigns, and any of their
            respective officers, directors, employees, agents, representatives,
            licensors, Advertisers, suppliers, and operational service providers
            harmless from and against any and all claims, actions, losses,
            expenses, damages and costs (including reasonable attorneys’ fees),
            resulting from any breach or violation of this Agreement by you,
            your use of the Site, or the public posting or other permitted use
            of your Postings.{" "}
          </p>

          <p>
            VII reserves the right to assume, at its sole expense, the exclusive
            defense and control of any such claim or action and all negotiations
            for settlement or compromise, and you agree to fully cooperate with
            VII in the defense of any such claim, action, settlement or
            compromise negotiations, as requested by VII.{" "}
          </p>

          <p>
            <a id="section-17"></a>
          </p>

          <h3 id="17-ads-and-malware">17. ADS AND MALWARE</h3>

          <p>
            We take great care and pride in creating this Site. We are always on
            the lookout for technical glitches that affect how the Site works.
            When we find them on our end, we will fix them. Unfortunately, your
            Device may cause some glitches that affect how you see our Site –
            and that is beyond our control.{" "}
          </p>

          <p>
            If you experience any unusual behavior, content or ads on the Site,
            it may be the result of Malware on your computer. Malware – short
            for MALicious softWARE – is a term used to broadly classify a form
            of software which is installed in a computer system with malicious
            intentions, usually without the owner’s knowledge or permission.
            Malware includes computer viruses, key loggers, malicious active
            content, rogue programs and dialers, among others. While we
            continuously work closely with our partners to ensure that
            everything on the Site is working properly, sometimes Malware
            programs on your Device may interfere with your experience on our
            Site and on other sites that you visit.{" "}
          </p>

          <p>
            We suggest that you take some of the following actions which may
            help to clean your Device and which could prevent future
            installations of Malware.{" "}
          </p>

          <ul>
            <li>
              Update your Device via tools available through your web browser or
              from your browser provider.{" "}
            </li>
            <li>
              Install a SpyWare Removal Tool such as{" "}
              <a href="http://www.safer-networking.org/en/index.html">
                Spybot Search and Destroy
              </a>{" "}
              or{" "}
              <a href="http://www.lavasoft.com/?domain=lavasoftusa.com">
                AdAware
              </a>{" "}
              to clean your Device of Malware.{" "}
            </li>
            <li>
              Install antivirus software, such as{" "}
              <a href="http://www.symantec.com/norton/products/index.jsp">
                Norton anti-virus
              </a>{" "}
              or{" "}
              <a href="http://home.mcafee.com/Default.aspx">
                McAfee Virus-shield
              </a>
              .{" "}
            </li>
          </ul>

          <p>
            Please note that we cannot be responsible for the effects of any
            third party software including Malware on your Device. Please make
            sure to carefully read the Help or Customer Support areas of any
            software download site. If you do discover any Malware on your
            Device, we also suggest you speak with a qualified technician for
            your Device. If, after taking the above actions, you are still
            experiencing any problems, please feel free to contact us at{" "}
            <a href="mailto:support@lookdifferent.org">
              support@lookdifferent.org
            </a>
            .{" "}
          </p>

          <p>
            <a id="section-18"></a>
          </p>

          <h3 id="18-privacy">18. PRIVACY</h3>

          <p>
            We respect your privacy and the use and protection of your
            Information. Please see our{" "}
            <Link to="/privacy-policy" className="link--inline">
              Privacy Policy
            </Link>{" "}
            for important information and disclosures relating to the collection
            and use of your Information in connection with your use of the Site.{" "}
          </p>

          <p>
            <a id="section-19"></a>
          </p>

          <h3 id="19-resolution-of-disputes-binding-arbitration-no-class-or-representative-actions-or-arbitrations">
            19. RESOLUTION OF DISPUTES: BINDING ARBITRATION; NO CLASS OR
            REPRESENTATIVE ACTIONS OR ARBITRATIONS
          </h3>

          <p>
            See our <a href="http://legal.viacom.com/FAQs">FAQs</a> for more
            information.
          </p>

          <h4 id="191-binding-arbitration-and-exclusions-from-arbitration">
            19.1 Binding Arbitration and Exclusions from Arbitration.
          </h4>

          <p>
            <strong>
              EXCEPT AS PROVIDED BELOW OR UNLESS YOU SUBMIT A VALID
              ARBITRATION/CLASS ACTION WAIVER OPT-OUT NOTICE (AS DESCRIBED IN{" "}
              <u>SECTION 19.5</u>), ANY AND ALL CLAIMS BETWEEN YOU AND VII WILL
              BE RESOLVED IN BINDING ARBITRATION RATHER THAN IN COURT.
            </strong>{" "}
          </p>

          <p>
            You and VII agree to submit to individual arbitration the resolution
            of any and all Claims by or between you and/or VII,{" "}
            <strong>
              except that you and VII agree that the following will not be
              subject to the mandatory arbitration provisions in this{" "}
              <u>Section 19.1</u>
            </strong>
            : (A) any Claim filed by you or VII with respect to any violation,
            enforcement or validity of patent, trademark, trade dress, service
            mark, copyright and/or trade secret rights of you, VII, or any third
            party, including, but not limited to, Claims related to content that
            you upload to or share on the Site and/or (B) you or VII may seek a
            preliminary injunction, restraining order or other provisional
            equitable relief in any court as provided in <u>Section 20</u> in
            connection with any Claim whereby you or VII, as applicable, may
            suffer immediate and irreparable harm for which money damages may be
            inadequate and impossible to calculate (including, but not limited
            to, a Claim under <u>Section 19.1(A)</u>), where such Claim under
            this <u>Section 19.1(B)</u> will not be subject to the informal
            dispute resolution procedures described in <u>Section 19.2</u>{" "}
            below; <u>provided</u>, however, that, subsequent to obtaining such
            preliminary injunction, restraining order or other provisional
            equitable relief, the Claim will then be submitted to arbitration in
            accordance with <u>Section 19</u>. You and VII agree that this
            Agreement affects interstate commerce, and that the enforceability
            of <u>Section 19</u> will be governed by, construed, and enforced,
            both procedurally and substantively, by the Federal Arbitration Act,
            9 U.S.C. sections 1–9 (“<u>FAA</u>”). Arbitration is the referral of
            a dispute to one or more impartial persons for a final and binding
            determination. There is no judge or jury in arbitration, discovery
            is more limited than in court, there are no class or representative
            proceedings, and court review of an arbitration decision is limited.
            An arbitrator must follow this Agreement and can award on an
            individual basis the same damages and relief as a court (including,
            but not limited to, injunctive and declaratory relief, statutory
            damages, and attorneys’ fees). “<u>Claim(s)</u>” means any dispute,
            claim or controversy by or between you and/or VII relating to the
            Site and/or this Agreement (including, but not limited to, this
            Site’s Privacy Policy and all Additional Terms, Rules, regulations,
            procedures and policies which we refer to in this Agreement), as may
            be modified from time-to-time, and the subject matter hereof,
            including, but not limited to, any contract, tort, statutory, or
            equity claims.{" "}
          </p>

          <h4 id="192-informal-dispute-resolution">
            19.2 Informal Dispute Resolution.
          </h4>

          <p>
            <strong>
              Except with respect to Claims described in <u>Section 19.1(B)</u>{" "}
              above, before either you or VII pursue or participate in any Claim
              against the other party in arbitration or court proceedings, you
              or VII must notify the other party of the Claim in writing at
              least 60 days in advance of initiating the arbitration or court
              proceeding in order to provide a reasonable opportunity to resolve
              the Claim.
            </strong>{" "}
            You may send a written notice of your Claim to VII at 1515 Broadway,
            New York, New York 10036, Attention: Viacom Inc. General Counsel.
            VII may send written or electronic notice of its Claim to your email
            address, VII account or any physical or other address VII has for
            you. The notice must describe the Claim in reasonable detail and set
            forth the relief requested so that the other party has an
            opportunity to adequately address the Claim. Except with respect to
            Claims described in <u>Section 19.1(B)</u> above, you and VII agree
            to negotiate in good faith with each other to try to informally
            resolve the Claim and, if you and VII do not reach an informal
            resolution of the Claim within 60 days, then the Claim may be
            submitted to binding arbitration as set forth in <u>Section 19</u>{" "}
            or court as permitted by <u>Section 19.1</u>.{" "}
          </p>

          <h4 id="193-arbitration-proceedings-and-costs">
            19.3 Arbitration Proceedings and Costs.
          </h4>

          <p>
            Any arbitration will be administered by the American Arbitration
            Association (“<u>AAA</u>”) pursuant to its Consumer Arbitration
            Rules (the “<u>AAA Rules</u>”), as modified by this Agreement. The
            AAA Rules, and other information about the AAA, are available at the
            AAA’s website at <a href="http://www.adr.org">www.adr.org</a>. A
            form for initiating arbitration proceedings is available on the
            AAA’s website (see Demand for Arbitration at{" "}
            <a href="https://www.adr.org/aaa/ShowPDF?doc=ADRSTG_015820">
              https://www.adr.org/aaa/ShowPDF?doc=ADRSTG_015820
            </a>{" "}
            and, for arbitrations in California,{" "}
            <a href="https://www.adr.org/aaa/ShowPDF?doc=ADRSTG_015822">
              https://www.adr.org/aaa/ShowPDF?doc=ADRSTG_015822
            </a>
            ,{" "}
            <u>but contact the AAA if you have issues accessing these links</u>)
            and arbitration proceedings shall be initiated in the location
            described in Section 20. As required by the AAA Rules, if you
            initiate the arbitration proceedings, you must send the original
            copy of the completed form to VII, which should be sent to VII at
            the following address: 1515 Broadway, New York, New York 10036,
            Attention: Viacom Inc. General Counsel. If your Claim is for $10,000
            or less, you may choose whether the arbitration will be conducted
            solely based on documents submitted to the arbitrator, through a
            telephonic hearing, or by an in-person hearing under the AAA Rules.
            In all cases, you and VII shall exchange documents and other
            information that you or VII intend to use in the arbitration.{" "}
          </p>

          <p>
            Upon filing of an arbitration demand for Claims up to $75,000, VII
            will reimburse you for all necessary filing, administration and
            arbitrator fees paid by you to the AAA or, if you wish VII to pay
            such fees directly to the AAA, you must request payment of such fees
            by VII by mail to the AAA along with your form initiating
            arbitration and VII will make arrangements to pay such fees directly
            to the AAA. In the event the arbitrator determines that the Claim(s)
            you assert in the arbitration are frivolous or vexatious, you agree
            to reimburse VII for all fees associated with the arbitration paid
            by VII on your behalf. You agree that VII shall have no obligation
            to pay any other fees except as determined by the arbitrator.{" "}
          </p>

          <p>
            For Claims that total more than $75,000, the AAA Rules will govern
            payment of filing, administration and arbitrator fees to the maximum
            extent permitted by law. The decision of the arbitrator will be
            binding and conclusive on all parties, and judgment to enforce the
            decision may be entered by any court of competent jurisdiction.
          </p>

          <h4 id="194-class-action-waiver">19.4 Class Action Waiver.</h4>

          <p>
            <strong>
              UNLESS YOU SUBMIT A VALID ARBITRATION/CLASS ACTION WAIVER OPT-OUT
              NOTICE (AS DESCRIBED IN SECTION 19.5), YOU AND VII AGREE THAT ANY
              DISPUTE RESOLUTION PROCEEDINGS WHETHER IN ARBITRATION OR IN COURT
              WILL BE CONDUCTED ONLY ON AN INDIVIDUAL BASIS AND NOT IN A CLASS,
              CONSOLIDATED OR REPRESENTATIVE ACTION.
            </strong>{" "}
            You and VII expressly agree that any Claim is personal to you and
            VII, shall only be resolved by an individual arbitration (or
            individual court proceedings with respect to Claims excluded from
            mandatory arbitration as described in <u>Section 19.1</u> of this
            Agreement), and shall in no event be brought as a class arbitration,
            a class action, or any other representative proceeding. The
            arbitrator (or court if the Claim is excluded from mandatory
            arbitration as described in <u>Section 19.1</u> of this Agreement)
            may only conduct an individual arbitration (or court action if the
            Claim is excluded from mandatory arbitration as described in{" "}
            <u>Section 19.1</u> of this Agreement), and may not consolidate more
            than one person’s claims and may not preside over any form of
            representative or class proceeding. If a court or arbitrator
            determines that this class action waiver is unenforceable in an
            action between you and VII, then this Agreement to arbitrate will be
            unenforceable. Neither you nor VII consent to class arbitration.{" "}
          </p>

          <h4 id="195-right-to-opt-out-of-mandatory-arbitration-and-class-action-waiver">
            19.5 Right to Opt Out of Mandatory Arbitration and Class Action
            Waiver.
          </h4>

          <p>
            <strong>
              IF YOU DO NOT WISH TO BE BOUND BY THE MANDATORY ARBITRATION AND
              CLASS ACTION WAIVER PROVISIONS IN{" "}
              <u>SECTIONS 19.1, 19.3 AND 19.4</u>, YOU MUST NOTIFY VII IN
              WRITING (THE “
              <u>Arbitration/Class Action Waiver Opt-Out Notice</u>”), WHERE THE
              ARBITRATION/CLASS ACTION WAIVER OPT-OUT NOTICE MEETS ALL OF THE
              FOLLOWING REQUIREMENTS:
            </strong>
          </p>

          <p>
            19.5.1 Sent by first class mail, postage prepaid, certified and
            return receipt requested or sent by overnight courier service (such
            as Federal Express) to Viacom Inc., Attention: Viacom Legal
            Department, Records Management, 1515 Broadway, 51st Floor, New York,
            NY 10036.{" "}
          </p>

          <p>
            19.5.2 Postmarked (if sent by first class mail) or deposited with
            the overnight courier service no later than (A), if you register as
            a new registered user of the Site on or after 2/9/2016 (“
            <u>Agreement to Arbitrate Date</u>”), 45 days after the date you
            accept this Agreement for the first time, unless an earlier deadline
            in <u>Section 19.5.2(C)</u> applies, (B), if you are already a
            registered user of the Site before the Agreement to Arbitrate Date,
            45 days after the earlier of either (I) your first log in to the
            Site on or after the Agreement to Arbitrate Date or (II) the date
            which email notice of the Agreement containing this Section 19, if
            any, was sent to the email address associated with your user
            registration, unless an earlier deadline in <u>Section 19.5.2(C)</u>{" "}
            applies or (C) for all other users of the Site, 45 days after you
            accept this Agreement for the first time on or after the Agreement
            to Arbitrate Date, unless a longer period is required by applicable
            law.{" "}
          </p>

          <p>
            <em>
              <u>
                Time Periods Applicable to Users of Multiple VII Websites,
                Applications or Other Interactive Services.
              </u>
            </em>{" "}
            If you are or become a user of more than one VII website,
            application or other interactive service,{" "}
            <u>
              you must provide the Arbitration/Class Action Waiver Opt-Out
              Notice within the earliest deadline applicable for any VII
              website, application or other interactive service for which you
              are or become a user
            </u>{" "}
            (for example, if the Agreement to Arbitrate Date is August 1 and if
            you are a pre-existing registered user of a VII website prior to
            August 1 who logins on August 1 and registers as a new registered
            user of another VII website on August 15, you must provide the
            Arbitration/Class Action Waiver Opt-Out Notice by September 15 (45
            days after August 1 and not 45 days after August 15)).{" "}
          </p>

          <p>
            19.5.3 Includes your first and last name, address, phone number,
            email address and, if applicable, your username if you are a
            registered user of the VII website(s), application(s) or other
            interactive services(s) along with an identification of the VII
            website(s), application(s) or other interactive services(s) for each
            such username. We shall use the foregoing information included in
            the Arbitration/Class Action Waiver Opt-Out Notice to record,
            process, maintain and administer your opt-out of the mandatory
            arbitration and class action waiver provisions and not for marketing
            purposes.{" "}
          </p>

          <p>
            19.5.4 Includes a statement that you do not agree to the mandatory
            arbitration and class action waiver.
          </p>

          <p>
            <strong>
              If the Arbitration/Class Action Waiver Opt-Out Notice meets all of
              the above requirements, you will be deemed to have opted out of
              the mandatory arbitration and class action waiver provisions in{" "}
              <u>Section 19.1</u>, <u>Section 19.3</u> and <u>Section 19.4</u>{" "}
              with respect to all VII websites, applications or other
              interactive services (including, but not limited to, those owned,
              operated and/or provided by Viacom Inc. and the corporate
              affiliates that Viacom Inc. directly or indirectly owns or
              controls such as those described in the link{" "}
              <a href="http://viacom.com/brands/pages/default.aspx">here</a>).
            </strong>{" "}
            Note that a valid Arbitration/Class Action Waiver Opt-Out Notice
            applies only to the individual identified in such notice as opting
            out.
          </p>

          <p>
            <strong>
              If the Arbitration/Class Action Waiver Opt-Out Notice does not
              meet all of the above requirements, you will not be deemed to have
              opted out of the mandatory arbitration and class action waiver
              provisions in <u>Section 19.1</u>, <u>Section 19.3</u> and{" "}
              <u>Section 19.4</u>.{" "}
            </strong>{" "}
          </p>

          <p>
            <a id="section-20"></a>
          </p>

          <h3 id="20-governing-law-arbitration-location-jurisdiction-venue-and-jury-trial-waiver">
            20. GOVERNING LAW, ARBITRATION LOCATION, JURISDICTION, VENUE AND
            JURY TRIAL WAIVER.
          </h3>

          <p>
            With the exception of the provision above that the enforceability of{" "}
            <u>Section 19</u> is governed both procedurally and substantively by
            the FAA, this Agreement and your use of the Site is otherwise
            governed by, construed and enforced in accordance with the laws of
            the State of New York (without regard to that state’s conflict of
            laws rules).{" "}
          </p>

          <p>
            You or VII shall initiate arbitration in the United States county or
            territory in which you reside or, if you do not reside in the United
            States or one of its territories, in the non-United States country
            in which you reside; <u>provided</u>, however, that (a) VII may
            request to transfer the arbitration to New York County, New York if
            it agrees to pay any additional fees or costs you incur as a result
            of the change in location as such additional fees or costs are
            determined by the arbitrator and to the maximum extent permitted by
            law and, upon such request, (b)(i) if you agree to such request, the
            arbitration shall be transferred to New York County, New York or
            (ii) if you do not agree to such request, VII shall have right to
            request that the arbitrator determine the location in which the
            arbitration shall be held. You and VII agree that any Claim that is
            allowed to proceed in court as set forth in <u>Section 19.1</u> of
            this Agreement (including, but not limited to, as a result of your
            submission of a valid Arbitration/Class Action Waiver Opt-Out
            Notice), or otherwise proceeds in court in the event the agreement
            to arbitrate above is found not to apply to you or a particular
            Claim as a result of a decision by the arbitrator or a court order,
            is subject to exclusive jurisdiction and venue in the State or
            Federal Courts situated in the Borough of Manhattan, New York City,
            State of New York.{" "}
          </p>

          <p>
            To the extent it may be applicable, you and VII agree to opt out
            from and expressly exclude any applicability of the Uniform Computer
            Information Transactions Act. If any Claim proceeds in court rather
            than in arbitration,{" "}
            <strong>YOU AND VII WAIVE ANY RIGHT TO A JURY TRIAL</strong>.
          </p>

          <p>
            <a id="section-21"></a>
          </p>

          <h3 id="21-miscellaneous-terms">21. MISCELLANEOUS TERMS</h3>

          <p>
            This Agreement, together with any Additional Terms, our{" "}
            <Link to="/privacy-policy" className="link--inline">
              Privacy Policy
            </Link>{" "}
            and any other regulations, procedures and policies which we refer to
            and which are hereby incorporated by reference, contains the entire
            understanding and agreement between you and the Site and supersedes
            any and all prior or inconsistent understandings relating to the
            Site and your use of the Site. This Agreement cannot be changed or
            terminated orally and may not be modified except as described in
            this Agreement. If any provision of this Agreement is held to be
            illegal, invalid or unenforceable, this will not affect any other
            provisions and the Agreement will be deemed amended to the extent
            necessary to make it legal, valid and enforceable. Any provision
            which must survive in order to allow us to enforce its meaning shall
            survive the termination of this Agreement; however, no action
            relating to this Agreement or your use of the Site, regardless of
            form or the basis of the claim, may be brought by you more than one
            (1) year after the cause of action has arisen (or if multiple
            causes, from the date the first such cause arose). The laws and
            regulations of the U.S. restrict the export and re-export of certain
            technology and you agree not to use, export or re-export any
            Material relating to the Site in violation of those laws and
            regulations. The section titles in this Agreement are for
            convenience only and do not have any legal effect.{" "}
          </p>

          <p>
            This Terms of Use Agreement was last modified on the date indicated
            above and is effective immediately.{" "}
          </p>

          <p>
            Copyright © 2018 Viacom International Inc. - All Rights Reserved.
          </p>
        </div>
      </article>
    </section>
  </Layout>
)

export default PrivacyPolicy
